export default {
  namespaced: true,
  state: {
    selectedEntity: {},
    selectedInstances: []
  },

  mutations: {
    updateStelectedEntity(state, data) {
      state.selectedEntity[data.key] = data.value;
      state.selectedEntity = JSON.parse(JSON.stringify(state.selectedEntity))
      state.selectedInstances = Object.values(state.selectedEntity)
    },
    deleteStelectedEntity(state, key) {
      state.selectedEntity[key] = null;
      delete state.selectedEntity[key];
      state.selectedInstances = Object.values(state.selectedEntity)
    },
  },
  actions: {
    updateStateEntiity({ commit }, data) {
      commit("updateStelectedEntity", data);
    },
    deleteStelectedEntity({ commit }, key) {
      commit("deleteStelectedEntity", key);
    },
  },
};

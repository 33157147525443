export default {
  namespaced: true,
  state: {
    currentUser: {},
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
  },
  actions: {
    handleUser({ commit }, val) {
      if (val) {
        commit("setCurrentUser", val);
      }
    },
  },
};

export default [
  {
    path: "/dashboard/user",
    name: "dashboard-analytics",
    component: () =>
      import("@/views/pages/contilt-pages/dashboard/contiltdashboard.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/dashboard/admin",
    name: "dashboard-ecommerce",
    component: () =>
      import("@/views/pages/contilt-pages/dashboard/contiltdashboard.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
];

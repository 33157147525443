export default [
  {
    path: "/reports",
    name: "user-reports",
    component: () => import("@/views/pages/contilt-pages/report/user-report/user-report-list/UserReportList.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
];

export default [



    // *===============================================---*
  // *--------- Tasks ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/task/list',
    name: 'apps-tasks-list',
    component: () => import('@/views/apps/task/TaskList.vue'),
    meta: {
      pageTitle: 'Tasks',
      contentClass: 'ecommerce-application',
 
    },
  },
  


   // *===============================================---*
  // *--------- TOKENS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tokens/list',
    name: 'apps-tokens-list',
    component: () => import('@/views/apps/token/tokens-list/TokensList.vue'),
  },
  {
    path: '/apps/tokens/edit/:id',
    name: 'apps-tokens-edit',
    component: () => import('@/views/apps/token/tokens-edit/TokensEdit.vue'),

  },
   // *===============================================---*
  // *--------- ORGANIZATION ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/organizations/edit/:id',
    name: 'apps-organizations-edit',
    component: () => import('@/views/pages/contilt-pages/settings/components/organization/organizations-edit/OrganizationsEdit.vue'),
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/users/edit/',
    name: 'apps-users-edit',
    component: () => import('@/views/pages/contilt-pages/settings/settingspage.vue'),
    meta: {
      resource: "Auth",
      action: "read",
    }
  },


]

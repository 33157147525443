export const inputTypes = {
  INPUT: "input",
  TEXTAREA: "textarea",
  STRING: "string",
  SELECT: "select",
  MULTISELECT: "multiselect",
  CHECKBOX: "checkbox",
  CHECKBOXLIST: "checkboxlist",
  SWITCH: "switch",
  RADIOLIST: "radiolist",
  FORMTAG: "formtag",
  IMAGE: "image",
  CSV_FILE: "csvFile",
  JSON_FILE: "jsonFile",
  FOLDER_UPLOAD: "folderUpload",
  FOR_INPUT: "forInput"
};

export const outputTypes = {
  IMAGELINK: "imagelink",
  IMAGELINKCARD: "imagelinkcard",
  IMAGECARDBASE64: "imagecardbase64",
  MULTIFIELD: "multifield", // top priority
  MAINTITLE: "maintitle",
  SUBTITLE: "subtitle",
  TEXT: "text",
  INPUT: "input",
  SPLITTER: "splitter",
  TEXTLIST: "list",
  STRINGCARD: "",
  ARRAYCARD: "",
  FOR_OUTPUT: "forOutput",
  DYNAMIC_OUTPUT: "dynamicOutput",
  SUBTEMPLATE_OUTPUT: "subTemplate",
};

export const ALL_LABELS = "All";
export const ALL_CATEGORIES = "All";

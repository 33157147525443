export default [
  {
    path: "/history",
    name: "genration-history",
    component: () => import("@/views/pages/contilt-pages/history/history.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
];

import lunr from "lunr";
// require("lunr-languages/lunr.stemmer.support")(lunr);
// require("lunr-languages/lunr.multi")(lunr);
// require("lunr-languages/lunr.de")(lunr);
// require("lunr-languages/lunr.es")(lunr);
// require("lunr-languages/lunr.fr")(lunr);
// require("lunr-languages/lunr.it")(lunr);
// require("lunr-languages/lunr.nl")(lunr);
// require("lunr-languages/lunr.pt")(lunr);
// require("lunr-languages/lunr.ro")(lunr);

//
// German , Spanish, French, Italian, Dutch, Portuguese , Romanian

export default {
  namespaced: true,
  state: {
    idx: {},
    searchResult: "",
  },
  mutations: {
    setData(state, val) {
      state.idx = val;
    },
    setSearchResult(state, val) {
      state.searchResult = val;
    },
  },
  actions: {
    buildtData({ commit }, data) {
      let idx = lunr(function () {
        // this.use(
        //   lunr.multiLanguage("en", "de", "es", "fr", "ro", "pt", "nl", "it")
        // );

        this.ref("id");
        this.field("name", { boost: 10, editDistance: 10 });
        this.field("description", { boost: 3, editDistance: 2 });

        this.k1(0.8);
        this.b(0);

        data.map((item) => {
          this.add({
            name: item.name,
            description: item.documentation.description,
            id: item.id,
          });
        });
      });
      commit("setData", idx);
    },
    search({ commit, state }, searchTerm) {
      let result;
      if (searchTerm) {
        result = state.idx.query(function (q) {
          // exact matches should have the highest boost
          lunr.tokenizer(searchTerm).forEach((x) => {
            q.term(x, { boost: 100 });
            // wildcard matches should be boosted slightly
            q.term(x, {
              boost: 10,
              usePipeline: true,
              wildcard:
                lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
            });
            // finally, try a fuzzy search, without any boost
            q.term(x, { boost: 5, usePipeline: false, editDistance: 1 });
          });
        });

        commit("setSearchResult", result);
      } else {
        commit("setSearchResult", "");
      }
    },
  },
};

import { ALL_CATEGORIES } from "./../../contilt/configs/inputconstants";
import axios from "@axios";

export default {
  namespaced: true,
  state: {
    categories: [],
    labels: {}, //main labels object
    selectedCategory: {},
    templates: [],
    selectedTemplate: null,
  },
  mutations: {
    getCategories(state, val) {
      state.categories = val;
    },
    setSelectedCategory(state, val) {
      state.selectedCategory = val;
    },
    setLabels(state, val) {
      state.labels = val;
    },
    setTemplates(state, val) {
      state.templates = val;
    },
    setSelectedTemplate(state, val) {
      state.selectedTemplate = val;
    },
    cleanState(state) {
      state.categories = [];
      state.labels = {};
      state.selectedCategory = {};
      state.templates = [];
      state.selectedTemplate = null;
    },
  },
  actions: {
    async getCategories({ commit, state, dispatch }) {
      let data = [];
      return new Promise((resolve, reject) => {
        axios
          .get("/templates", { params: { limit: 300, selectedFields:"id,name,documentation,categories,labels,imageBase64,imagelink" } })
          .then((response) => {
            data = response.data;
            if (response.data) {
              commit("getCategories", [
                { id: ALL_CATEGORIES, value: ALL_CATEGORIES },
                ...response.data.categories,
              ]);
              commit("setLabels", { All: [], ...response.data.labels });
              commit("setTemplates", response.data.templates.results);
              commit("setSelectedCategory", {
                id: ALL_CATEGORIES,
                value: ALL_CATEGORIES,
              });
              dispatch(
                "lunrSearchTemplates/buildtData",
                response.data.templates.results,
                { root: true }
              );
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
            this.generationLoading = false;
          });
      });
    },
    selectedCategory({ commit }, val) {
      if (val) {
        commit("setSelectedCategory", val);
      }
    },
    cleanState({ commit }) {
      commit("cleanState");
    },
  },
};

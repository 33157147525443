import axios from "@axios";

const userTheme = [
  "light-primary",
  "light-success",
  "light-danger",
  "light-warning",
  "light-info",
];
export default {
  namespaced: true,
  state: {
    data: {
      usersData: [],
      historyData: [],
      loading: true,
    },
  },
  mutations: {
    setData(state, val) {
      state.data = val;
    },
    setLoading(state, val) {
      state.data.loading = val;
    },
    setUSersData(state, val) {
      state.data.usersData = val;
    },
    setHistoryData(state, val) {
      state.data.historyData = val;
    },
    setResultsGeneratedData(state, val) {
      state.data.resultsGenerated = val;
    },
    setTotalArticlesGenerated(state, val) {
      state.data.totalArticlesGenerated = val;
    },
    setTotalWordsGenerated(state, val) {
      state.data.totalWords = val;
    },
    deleteStoreData(state) {
      state.data = {};
    },
  },
  actions: {
    getDashboardData({ commit }) {
      let data = [];
      commit("setLoading", true);

      axios
        .get("/templatescalllog/getDashboardStat", {})
        .then((response) => {
          data = response.data;
          if (response.data) {
            let dataArray = [];
            response.data.mostActiveUsers.map((item, index) => {
              if (item.user.length) {
                dataArray.push({
                  avatar: "PocketIcon",
                  avatarVariant: userTheme[index % 5],
                  deduction: true,
                  username: item.user[0].name || "username" + index,
                  payment: item.totalCalls,
                  id: item.user[0]._id,
                });
              }
            });

            //history table
            let history = [];
            let totalWords = 0;
            response.data.history.map((item, index) => {
              totalWords += item.words || 0;
              if (
                item &&
                item.template &&
                item.template._id &&
                item.template.name
              ) {
                history.push({
                  id: item.id,
                  templateId: item.template._id,
                  template: item.template.name || "template" + index,
                  topic: item.topic,
                  words: item.words || "10",
                  rating: item.rating || "2.5",
                  issues: "no issues",
                });
              }
            });
            //results generated graph
            let categories = [];
            let score = [];
            let totalArticlesGenerated = 0;
            response.data.lastXCalls.map((item, index) => {
              categories.push(item._id);
              score.push(item.count);
              totalArticlesGenerated += item.count;
            });

            // response.data.lastXCalls.map;
            commit("setData", response.data);
            commit("setUSersData", dataArray);
            commit("setHistoryData", history);
            commit("setResultsGeneratedData", { categories, score });
            commit("setTotalArticlesGenerated", totalArticlesGenerated);
            commit("setTotalWordsGenerated", totalWords);
            commit("setLoading", false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
